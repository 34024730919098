'use strict'

angular.module('cb.messages-service', [])
    .factory('MessagesService', ["ApiService", function (ApiService) {

		var messagesService = {
			GetProformaList: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/getAlertProformaList';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			GetProformaAlerts: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/getAlertProforma';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
            GetAlertMetrics: function (data) {
                try {
                    var url = '/webapi/api/v1/leadprofile/getAlertMetrics'; 
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetReminderMetrics: function (data) {
                try {
                    var url = '/webapi/api/v1/leadprofile/getReminderMetrics'; 
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetReminderMetricsCount: function (data) {
                try {
                    var url = '/webapi/api/v1/leadprofile/getReminderMetricsCount'; 
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetFollowUpMetrics: function (data) {
                try {
                    var url = '/webapi/api/v1/leadprofile/getFollowUpMetrics'; 
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetFollowUpMetricsCount: function (data) {
                try {
                    var url = '/webapi/api/v1/leadprofile/getFollowUpMetricsCount'; 
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetUserHistoryItems: function () {
                try {
                    var url = '/webapi/api/v1/history'; 
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            MarkAlertAsDealt: function (data) {
                try {
                    var url = '/webapi/api/v1/alert/dealWithAlert'; 
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            DeleteAlert: function (data) {
                try {
                    var url = '/webapi/api/v1/alert/deleteAlert'; 
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
        };
        return messagesService;
    }]);
